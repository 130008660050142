.post {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #1F2A40;
    margin-top: 10px;
    border: 0px solid lightgray;
    border-radius: 5px;
    max-width: 700px;
    box-shadow: 0px 5px 8px -9px solid rgab(0, 0, 0, 0.5);
  }
  
  .post__info {
    display: flex;
    align-items: center;
  }
  
  .post__info > h4 {
    margin-left: 10px;
    cursor: pointer;
    font-size: 13px;
  }
  .post__info > h4:hover {
    text-decoration: underline;
  }
  
  .post__info > small {
    margin-left: 10px;
  }
  
  .post__body {
    display: flex;
    flex-direction: column;
  }
  
  .post__body > .post__question {
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 1;
  }
  
  .post__question > .post__btnAnswer {
    margin-left: calc(100% - 75px);
    margin-bottom: 10px;
    cursor: pointer;
    padding: 7px;
    background-color: #3e4396;
    outline: none;
    border: none;
    color: #e0e0e0;
    font-weight: 300;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .post__btnAnswer:hover {
    color: #222;
    background: #e0e0e0;
  }
  
  .post__question > p:hover {
    text-decoration: underline;
  }
  
  .post__body > .post__answer > p {
    margin-bottom: 10px;
  }
  
  /* .post__image {
    background: rgb(0, 140, 255);
  } */
  
  .post__body > img {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .post__footer {
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
  }
  
  .post__footer > .MuiSvgIcon-root {
    color: rgb(217, 217, 217);
    cursor: pointer;
    margin-left: 30px;
  }
  
  .post__footerAction {
    background-color: #4cceac;
    padding: 2px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    border-radius: 33px;
    margin-right: 10px;
  }
  .post__footerAction > .MuiSvgIcon-root {
    color: rgb(0, 0, 0);
    cursor: pointer;
  }
  
  .post__footerAction > .MuiSvgIcon-root:hover {
    color: rgb(255, 255, 255);
  }
  
  .post__footerLeft {
    margin-left: auto;
  }
  
  .post__footerLeft > .MuiSvgIcon-root {
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin-left: 30px;
  }
  
  .modal__question {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .modal__question > h1 {
    color: #4cceac;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .modal__question > p {
    color: rgb(0, 0, 0);
    font-size: small;
  }
  
  .modal__question > p > .name {
    color: black;
    font-weight: bold;
  }
  
  .modal__answer {
    display: flex;
    padding-top: 20px;
    flex: 1;
  }
  
  .modal__answer > textarea {
    width: 100%;
    height: 200px;
    padding: 5px;
    font-size: 15px;
    color: black;
  }
  
  .modal__button {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
  }
  
  .modal__button > .cancle {
    border: none;
    margin-top: 10px;
    outline: none;
    color: gray;
    font-weight: 500;
    padding: 10px;
    border-radius: 33px;
    cursor: pointer;
  }
  
  .modal__button > .cancle:hover {
    color: red;
  }
  .modal__button > .add {
    border: none;
    outline: none;
    margin-top: 5px;
    background-color: #222;
    color: white;
    font-weight: 700;
    padding: 10px;
    border-radius: 33px;
    cursor: pointer;
    width: 50%;
  }
  .modal__button > .add:hover {
    background-color: #e0e0e0;
    color: #222;
  }
  
  .react-responsive-modal-modal {
    /* width: 600px; */
    height: 400px;
  }
  
  .quill {
    width: 100%;
    height: 120px;
  }