.widget__contents {
    display: flex;
    cursor: pointer;
  }
  
  .widget__content {
    margin: 5px;
    display: flex;
    padding: 5px;
    border-bottom: 1px solid lightgray;
  }
  
  .widget__content > img {
    height: 30px;
    border-radius: 5px;
  }
  
  .widget__contentTitle {
    margin-left: 10px;
  }
  .widget__contentTitle > h5 {
    color: rgb(185, 185, 185);
  }
  
  .widget__contentTitle > p {
    color: gray;
    font-size: small;
  }