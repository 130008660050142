.quora{
    width: "100%";
    min-width: fit-content;
}

.quora__contents{
    display: flex;
    justify-content: center;
    padding: 50px 0;
    background-color: #141b2d;
    min-width: fit-content;
    width: 100%;
}
  
.quora__content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 10px;
    width: 100%;
    max-width: 1200px;
}

.react-responsive-modal-modal {
    width: 90vw;
    height: 90vh;
  }
  
.quill {
    height: 50vh;
  }
  
.react-responsive-modal-overlay {
    background: rgb(0 0 0 / 75%);
  }